<template>
  <div class="row chat-page">
    <div
      id="chatConversation"
      class="chat-list col-md-3 bg-white br-left"
      v-show="
      (this.$conversationsList &&
        this.$conversationsList.group &&
        this.$conversationsList.group.length) ||
      (this.$conversationsList &&
        this.$conversationsList.direct &&
        this.$conversationsList.direct.length) || (this.$conversationsList &&
          this.$conversationsList.loading)
    "
    >
      <ChatChannel
        @changeChanel="changeChanel"
        @loading="
        (stt) => {
          this.loading = stt;
        }
      "
        :activeConversation="conversation"
      ></ChatChannel>
    </div>
    <div
      class="bg-white"
      :class="
      (this.$conversationsList &&
        this.$conversationsList.group &&
        this.$conversationsList.group.length) ||
        (this.$conversationsList &&
          this.$conversationsList.direct &&
          this.$conversationsList.direct.length) || (this.$conversationsList &&
            this.$conversationsList.loading)
        ? 'col-md-9 h-full'
        : 'col-12 h-full'
    "
    >
      <ChatDetail
        :circleID="info.circle_id"
        :activeConversation="conversation"
        class="bg-white"
        :propName="chanelName()"
        v-show="!loading"
      ></ChatDetail>
      <div class="loading" v-show="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatChannel from './ChatChannel.vue'
import ChatDetail from '@/components/Chats/ChatDetail.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'ChatPage',
  components: { ChatChannel, ChatDetail },
  data () {
    return {
      conversation: null,
      loading: false,
      info: {}
    }
  },
  created () {
    this.setReloadDataChatList(true)
  },
  watch: {
    $conversationsClient: {
      async handler () {
        await this.changeChanel(this.info)
      }
    }
  },
  methods: {
    ...mapMutations({
      setReloadDataChatList: 'chatConversation/SET_RELOAD_DATA_CHAT_LIST'
    }),
    async changeChanel (conversationInfo) {
      if (!conversationInfo || !conversationInfo?.twilio_conv_id) {
        return
      }

      this.info = conversationInfo

      this.conversation =
        (this.$conversationsClient &&
          (await this.$conversationsClient.getConversationBySid(
            conversationInfo?.twilio_conv_id || this.$route.query?.sid
          ))) ||
        null

      // eslint-disable-next-line no-unused-expressions
      this.conversation?.setAllMessagesRead()
    },
    chanelName () {
      let info = this.info
      if (!info) return

      return (
        info?.to?.name ||
        info?.to?.email ||
        info?.name ||
        info?.email ||
        this.$user?.name ||
        ''
      )
    }
  }
}
</script>

<style scoped>
.br-left {
  border-right: 1px solid #f1f1f5;
}

.chat-page {
  overflow: hidden;
  height: calc(100vh - 61px);
}

.chat-list {
  height: 100%;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
