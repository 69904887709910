var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row chat-page"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
    (this.$conversationsList &&
      this.$conversationsList.group &&
      this.$conversationsList.group.length) ||
    (this.$conversationsList &&
      this.$conversationsList.direct &&
      this.$conversationsList.direct.length) || (this.$conversationsList &&
        this.$conversationsList.loading)
  ),expression:"\n    (this.$conversationsList &&\n      this.$conversationsList.group &&\n      this.$conversationsList.group.length) ||\n    (this.$conversationsList &&\n      this.$conversationsList.direct &&\n      this.$conversationsList.direct.length) || (this.$conversationsList &&\n        this.$conversationsList.loading)\n  "}],staticClass:"chat-list col-md-3 bg-white br-left",attrs:{"id":"chatConversation"}},[_c('ChatChannel',{attrs:{"activeConversation":_vm.conversation},on:{"changeChanel":_vm.changeChanel,"loading":(stt) => {
        this.loading = stt;
      }}})],1),_c('div',{staticClass:"bg-white",class:(this.$conversationsList &&
      this.$conversationsList.group &&
      this.$conversationsList.group.length) ||
      (this.$conversationsList &&
        this.$conversationsList.direct &&
        this.$conversationsList.direct.length) || (this.$conversationsList &&
          this.$conversationsList.loading)
      ? 'col-md-9 h-full'
      : 'col-12 h-full'},[_c('ChatDetail',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"bg-white",attrs:{"circleID":_vm.info.circle_id,"activeConversation":_vm.conversation,"propName":_vm.chanelName()}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading"},[_vm._m(0)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }