<template>
  <div :class="loading ? 'c-loading' : ''" id="chatChannleList">
    <div class="d-flex justify justify-content-between px-3 align-items-center">
      <p class="fs-24 fw-600 txt-black mt-3">Chat</p>
    </div>
    <div class="px-3 mt-3" v-show="$conversationsList && !$conversationsList.loading">
      <div class="mb-3">
        <div
          class="d-flex align-items-center cursor-pointer mb-2"
          @click="group_chat = !group_chat"
        >
          <p class="fs-12 fw-500 mb-0 txt-dark-2">
            <svg
              width="10"
              height="6"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-1 mr-1"
              v-show="group_chat"
            >
              <path
                d="M7 7.16833L6.64645 7.52189L7 7.87544L7.35355 7.52189L7 7.16833ZM0.646447 1.52189L6.64645 7.52189L7.35355 6.81478L1.35355 0.814782L0.646447 1.52189ZM7.35355 7.52189L13.3536 1.52189L12.6464 0.814782L6.64645 6.81478L7.35355 7.52189Z"
                fill="#151515"
              />
            </svg>

            <svg
              width="6"
              height="10"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-1 mr-1"
              v-show="!group_chat"
            >
              <path
                d="M6.5 7.16833L6.85355 7.52189L7.20711 7.16833L6.85355 6.81478L6.5 7.16833ZM0.853554 13.5219L6.85355 7.52189L6.14645 6.81478L0.146447 12.8148L0.853554 13.5219ZM6.85355 6.81478L0.853553 0.814782L0.146447 1.52189L6.14645 7.52189L6.85355 6.81478Z"
                fill="#151515"
              />
            </svg>
            {{$t('chat_features.lbl_group_header')}}
          </p>
        </div>
        <div
          v-if="$conversationsList && $conversationsList.group && $conversationsList.group.length"
          :set="(groups = $conversationsList.group)"
          class="mt-1"
          v-show="group_chat"
        >
          <div
            class="mb-2 cursor-pointer"
            v-for="(group, index) in groups"
            :key="index + 'group'"
            @click="goToChat(group)"
            :id="group.twilio_conv_id"
          >
            <ChatGroupListItem :group="group" :activeConversation="activeConversation" />
          </div>
          <div id="nextPageChatGroup" class="text-center pl-3">
            <div v-if="isShowNextPageChatGroup">
              <button
                class="btn btn-sm btn-info w-full"
                @click.stop="(e) => handleNextPageChatList(e, CHAT_CONVERSATION_TYPE.GROUP)"
              >
                <span>Xem thêm</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center cursor-pointer mb-3" @click="chat = !chat">
          <p class="fs-12 fw-500 mb-0 txt-dark-2">
            <svg
              width="10"
              height="6"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-1 mr-1"
              v-show="chat"
            >
              <path
                d="M7 7.16833L6.64645 7.52189L7 7.87544L7.35355 7.52189L7 7.16833ZM0.646447 1.52189L6.64645 7.52189L7.35355 6.81478L1.35355 0.814782L0.646447 1.52189ZM7.35355 7.52189L13.3536 1.52189L12.6464 0.814782L6.64645 6.81478L7.35355 7.52189Z"
                fill="#151515"
              />
            </svg>

            <svg
              width="6"
              height="10"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-1 mr-1"
              v-show="!chat"
            >
              <path
                d="M6.5 7.16833L6.85355 7.52189L7.20711 7.16833L6.85355 6.81478L6.5 7.16833ZM0.853554 13.5219L6.85355 7.52189L6.14645 6.81478L0.146447 12.8148L0.853554 13.5219ZM6.85355 6.81478L0.853553 0.814782L0.146447 1.52189L6.14645 7.52189L6.85355 6.81478Z"
                fill="#151515"
              />
            </svg>
            {{$t('chat_features.lbl_one_header')}}
          </p>
        </div>
        <div v-if="$conversationsList && $conversationsList.direct" v-show="chat">
          <div
            class="row mb-2 cursor-pointer"
            v-for="(single, index) in $conversationsList.direct"
            :key="index + 'conversation'"
            @click="goToChat(single)"
            :id="single.twilio_conv_id"
          >
            <ChatSingleListItem :single="single" :activeConversation="activeConversation" />
          </div>
          <div id="nextPageChatDirect" class="text-center pl-3">
            <div v-if="isShowNextPageChatDirect">
              <button
                class="btn btn-sm btn-info w-full"
                @click.stop="(e) => handleNextPageChatList(e, CHAT_CONVERSATION_TYPE.DIRECT)"
              >
                <span>Xem thêm</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-sc" v-show="$conversationsList && $conversationsList.loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/SearchInput'
import ChatGroupListItem from '@/components/Chats/ChatGroupListItem.vue'
import ChatSingleListItem from '@/components/Chats/ChatSingleListItem.vue'
import { mapGetters, mapActions } from 'vuex'
import { CHAT_CONVERSATION_TYPE } from '@/utils/constants'

export default {
  name: 'ChatChannel',
  components: { SearchInput, ChatGroupListItem, ChatSingleListItem },
  props: ['activeConversation'],
  data () {
    return {
      group_chat: true,
      chat: true,
      circle: [],
      single_chats: [],
      workspace: [],
      message_count_list: [],
      list_group_chats: [],
      list_single_chats: [],
      sortedCvs: [],
      chatInfo: null,
      CHAT_CONVERSATION_TYPE,
      scrollTop: 0
    }
  },
  computed: {
    ...mapGetters({
      chatGroups: 'chatConversation/getGroupChatListConversation',
      chatDirect: 'chatConversation/getDirectChatListConversation',
      paramsGroup: 'chatConversation/getParamsGroupChatListConversation',
      paramsDirect: 'chatConversation/getParamsDirectChatListConversation'
    }),
    loading () {
      return this.$conversationsList?.loading
    },
    isShowNextPageChatGroup () {
      return (
        this.chatGroups?.page?.page_num < this.chatGroups?.page?.total_page
      )
    },
    isShowNextPageChatDirect () {
      return (
        this.chatDirect?.page?.page_num < this.chatDirect?.page?.total_page
      )
    }
  },
  watch: {
    '$conversationsList.loading' (_new, old) {
      if (old && !_new) {
        this.handleFirstChannel()
      }
    },
    '$conversationsList.total_unread': {
      async handler () {
        await this.goToChat(this.chatInfo)
      }
    }
  },
  mounted () {
    if (!this.$conversationsList.loading) this.handleFirstChannel()
  },
  methods: {
    ...mapActions('chatConversation', ['getChatListData']),
    async handleFirstChannel () {
      let item =
        this.$conversationsList.group && this.$conversationsList.group.length
          ? this.$conversationsList.group[0]
          : {}

      if (this.$route?.query?.sid) {
        let cvs = null
        try {
          cvs = await this.$rf
            .getRequest('AuthRequest')
            .getInfoConversation(this.$route?.query?.sid)
            .then(r => {
              return r.data
            })
        } catch (error) {}
        if (cvs) {
          item = cvs
        } else {
          let query = Object.assign({}, this.$route.query)
          query.sid = item?.twilio_conv_id
          this.$router.replace({ query })
        }
      }

      await this.goToChat(item)
    },
    async goToChat (cvsInfo, retry = 0) {
      if (!cvsInfo) return

      this.chatInfo = cvsInfo

      try {
        this.$emit('loading', true)

        if (
          (this.chatInfo?.unread_count || this.chatInfo?.unread) &&
          this.$conversationsList?.total_unread
        ) {
          this.$conversationsList.total_unread -=
            this.chatInfo?.unread_count || this.chatInfo?.unread
          this.chatInfo.unread_count = 0
          this.chatInfo.unread = 0
        }

        this.$emit('changeChanel', this.chatInfo)
        this.$router
          .push({ query: { sid: this.chatInfo.twilio_conv_id } })
          .catch(e => {})
      } catch (error) {
        console.log(error)
        setTimeout(() => {
          if (retry > 3) this.goToChat(this.chatInfo, retry++)
        }, 300)
      } finally {
        this.$emit('loading', false)
      }
    },
    isChatGroup (type) {
      return type === CHAT_CONVERSATION_TYPE.GROUP
    },
    isChatDirect (type) {
      return type === CHAT_CONVERSATION_TYPE.DIRECT
    },
    async handleNextPageChatList (e, type) {
      let pageIndex = this.isChatGroup(type)
        ? this.chatGroups.page?.page_num
        : this.chatDirect.page?.page_num
      const currentData = this.isChatGroup(type)
        ? this.$conversationsList?.group
        : this.isChatDirect(type)
          ? this.$conversationsList?.direct
          : []
      const params = this.isChatGroup(type)
        ? this.paramsGroup
        : this.paramsDirect
      const res = await this.getChatListData({
        ...params,
        type,
        page_num: ++pageIndex
      })

      // const el = this.isChatGroup(type)
      //   ? document.getElementById('nextPageChatGroup')
      //   : document.getElementById('nextPageChatDirect')
      this.scrollTop += e.pageY
      const el = document.querySelector('#chatConversation')

      setTimeout(() => {
        // el.scrollIntoView({ behavior: 'smooth' })
        el.scrollTop = this.scrollTop
      }, 100)

      const dataNextPage = res?.data || []
      const newData = this.isChatGroup(type)
        ? {
          group: [...currentData, ...dataNextPage],
          direct: this.$conversationsList.direct
        }
        : this.isChatDirect(type)
          ? {
            group: this.$conversationsList.group,
            direct: [...currentData, ...dataNextPage]
          }
          : {
            group: this.$conversationsList.group,
            direct: this.$conversationsList.direct
          }

      this.$conversationsList = {
        ...this.$conversationsList,
        ...newData
      }

      const totalUnread = this.$conversationsList.group
        .concat(this.$conversationsList.direct)
        .reduce((acc, chat) => acc + chat.unread, 0)

      this.$conversationsList = {
        ...this.$conversationsList,
        total_unread: totalUnread || 0
      }
    }
  }
}
</script>

  <style scoped>
.txt-dark-2 {
  color: #84818a;
}
.c-loading {
  cursor: progress;
}
.loading-sc {
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
